import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

// Connects to data-controller="admins--movie-url"
export default class extends Controller {
  static targets = ['edit'];

  connect() {}

  onHover(event) {
    this.editTarget.classList.remove('d-none');
  }

  onBlur(event) {
    this.editTarget.classList.add('d-none');
  }

  async onAdd(e) {
    e.preventDefault();
    const { guideId } = e.params;

    const targetList = document.getElementsByClassName('introduction-movies');
    const { data: dom } = await axios.get(
      `/admins/guides/${guideId}/introduction_movies/new`,
    );
    document
      .getElementById('introduction-movie-wrapper')
      .insertAdjacentHTML('beforeend', dom);

    if (targetList.length >= 3) {
      document
        .getElementById('guide_introduction_movie_add_btn')
        .classList.add('d-none');
    }
  }
  onDelete(e) {
    const { index } = e.params;
    e.preventDefault();
    const target = document.getElementById(`introduction-movies-${index}`);
    target.classList.add('d-none');
    const deleteTarget = document.getElementById(
      `guide_guide_introduction_movies_attributes_${index}__destroy`,
    );
    deleteTarget.value = true;
    document
      .getElementById('guide_introduction_movie_add_btn')
      .classList.remove('d-none');
  }
}
