import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="partial--card-select"
export default class extends Controller {
  static targets = ['inputPercent', 'customPercentResult', 'resultPercent'];
  static values = {
    percent: {
      type: Number,
      default: 0,
    },
    basePurchase: Number,
    inputPercent: Number,
    currencyCode: {
      type: String,
      default: 'JPY',
    },
  };
  connect() {
    this.customPercentResultTarget.textContent = this.formattedPercentResult;
  }
  clickCard({ params: { percent = 0, index = 0, type = '' } }) {
    this.percentValue = percent;
    this.resultPercentTarget.value = percent;
    // クリックしたcardをactiveにする
    const cards = document.querySelectorAll('.tip-card');
    cards.forEach((card) => {
      card.classList.remove('active');
    });
    cards[index].classList.add('active');
    if (type !== 'custom') {
      this.inputPercentTarget.value = 0;
      this.customPercentResultTarget.textContent = this.formattedPercentResult;
    }
  }

  inputCard({ target: { value } }) {
    if (!value) {
      return;
    }
    const percent = parseInt(value);
    if (!isNaN(percent)) {
      this.percentValue = percent;
      this.resultPercentTarget.value = this.percentValue;
    }
  }
  calculate(e) {
    this.inputPercentValue = e.target.value;
    if (/^\d+$/.test(this.inputPercentValue)) {
      // 少数第２位まで残す
      // 後ろ2桁を0で埋める
      const result =
        Math.floor(this.inputPercentValue * this.basePurchaseValue) / 100;
      this.customPercentResultTarget.textContent = `($${
        this.currencyCodeValue === 'JPY' ? result.toFixed() : result.toFixed(2)
      })`;
    }
  }

  get formattedPercentResult() {
    return this.currencyCodeValue === 'JPY'
      ? `(${this.currencyCodeValue} 0)`
      : `(${this.currencyCodeValue} 0.00)`;
  }
}
