import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';

// Connects to data-controller="schedule"
export default class extends Controller {
  static targets = ['dateInput', 'dateResult'];
  static values = {
    dates: Array,
    disabledDates: Array,
  };
  connect() {
    this.initFlatpickr();
  }
  clickDate(event) {
    this.datesValue = event.target.value
      .split(',')
      .filter((_) => !!_)
      .map((_) => _.trim());
  }

  clear(event) {
    event.preventDefault();
    this.datesValue = [];
    this.initFlatpickr();
  }

  initFlatpickr() {
    this.datesValue = [];
    flatpickr(this.dateInputTarget, {
      locale: 'en',
      dateFormat: 'Y/m/d',
      inline: true,
      mode: 'multiple',
      minDate: new Date().fp_incr(1),
      disable: this.disabledDatesValue,
    }).clear();
  }

  submit(event) {
    event.preventDefault();
    this.dateResultTarget.innerHTML = this.datesValue.map(
      (date) =>
        `<input type="hidden" name="schedule[dates][]" value="${date}">`,
    );
    this.element.requestSubmit();
  }
}
