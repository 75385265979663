import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="price"
export default class extends Controller {
  static targets = ['price', 'currency'];
  static values = {
    priceCents: Number,
    currency: String,
    placeGuides: Array,
  };

  connect() {}

  changePlace(event) {
    const targetPlaceGuide = this.placeGuidesValue.find(
      (placeGuide) => placeGuide.place_id === parseInt(event.target.value),
    );
    if (targetPlaceGuide) {
      this.priceTarget.value = targetPlaceGuide.price_cents;
      this.currencyTarget.value = targetPlaceGuide.price_currency;
    } else {
      this.priceTarget.value = null;
      this.currencyTarget.value = null;
    }
  }
}
