import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="users--registrations--new"
export default class extends Controller {
  static targets = ['tos', 'submit'];
  static values = {
    tos: {
      type: Boolean,
      default: false,
    },
  };
  connect() {}

  checkToS() {
    this.tosValue = !this.tosValue;
    this.tosTarget.checked = this.tosValue;
  }

  /**
   * 利用規約のリンクをクリックしたときに呼ばれる
   * @param event
   */
  clickToSLink(event) {
    if (!this.tosValue) {
      this.checkToS();
    }
    event.preventDefault();
    window.open('https://skiday.co.jp/riyoukiyaku', '_blank');
  }
  tosValueChanged() {
    // if (this.tosValue) {
    //   this.submitTarget.removeAttribute('disabled');
    // } else {
    //   this.submitTarget.setAttribute('disabled', 'disabled');
    // }
  }
}
