import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="guide--schedules--tips--show"
export default class extends Controller {
  static targets = ['inputPercent', 'customPercentResult'];
  static values = {
    basePurchase: Number,
    inputPercent: Number,
  };
  connect() {
    this.customPercentResultTarget.textContent = '($0.00)';
  }

  calculate(e) {
    this.inputPercentValue = e.target.value;
    if (/^\d+$/.test(this.inputPercentValue)) {
      // 少数第２位まで残す
      // 後ろ2桁を0で埋める
      const dollars = (
        Math.floor(this.inputPercentValue * this.basePurchaseValue) / 100
      ).toFixed(2);
      this.customPercentResultTarget.textContent = `($${dollars})`;
    }
  }
}
