import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import HttpRequest from '../../utils/HttpRequest';
import { mask, unmask } from '../../utils/loading';

// Connects to data-controller="guides--show"
export default class extends Controller {
  static targets = [
    'dateInput',
    'totalAmount',
    'days',
    'submitButton',
    'form',
    'selectedDays',
    'purchase',
    'selectedPlaceId',
    'currencyCode',
  ];
  static values = {
    dates: Array,
    purchase: Number,
    disabledDates: Array,
    availableDates: Array,
    placeId: Number,
    guideId: Number,
  };
  connect() {
    this.initFlatpickr({
      disabledDates: this.disabledDatesValue,
      availableDates: this.availableDatesValue,
    });
  }

  async changePlace(event) {
    this.placeIdValue = Number(event.target.value);
    mask();
    try {
      const {
        data: {
          scheduled_dates: disabledDates,
          available_dates: availableDates,
          purchase,
          currency_code,
        },
      } = await HttpRequest.get(
        `/api/guides/${this.guideIdValue}/places/${this.placeIdValue}`,
      );
      this.purchaseValue = purchase;
      this.currencyCodeTargets.forEach((target) => {
        target.textContent = currency_code;
      });
      this.datesValue = [];
      this.disabledDatesValue = disabledDates;
      this.availableDatesValue = availableDates;
      this.initFlatpickr();
    } finally {
      unmask();
    }
  }

  clickDate(event) {
    const value = event.target.value;
    // "yyyy/mm/dd to yyyy/mm/dd" の形式で入ってくるので分割する
    const [start, end] = value.split(' to ');
    if (start && !end) {
      this.datesValue = [value];
    } else {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const dates = [];
      while (startDate <= endDate) {
        dates.push(
          startDate.toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        );
        startDate.setDate(startDate.getDate() + 1);
      }
      this.datesValue = dates;
    }
  }

  submitReservation(event) {
    event.preventDefault();
    if (this.datesValue.length === 0) {
      return;
    }
    this.selectedPlaceIdTarget.value = this.placeIdValue;
    this.selectedDaysTarget.value = this.datesValue;
    this.formTarget.submit();
  }

  datesValueChanged() {
    const dateLength = this.datesValue.length;
    const total = this.purchaseValue * dateLength;
    this.totalAmountTarget.textContent = total.toLocaleString();
    this.daysTarget.textContent = dateLength;
    this.submitButtonTarget.disabled = dateLength === 0;
  }

  purchaseValueChanged() {
    // 1000ごとにカンマ区切りにする
    this.purchaseTarget.textContent =
      this.purchaseValue.toLocaleString('ja-JP');
  }

  initFlatpickr() {
    this.datesValue = [];
    flatpickr(this.dateInputTarget, {
      locale: 'en',
      dateFormat: 'Y/m/d',
      inline: true,
      mode: 'range',
      minDate: new Date().fp_incr(1),
      disable: [],
      enable: this.availableDatesValue,
    }).clear();
  }
}
