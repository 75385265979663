import axios from 'axios';

class HttpRequest {
  get(url, config = null) {
    return axios.get(url, config);
  }

  post(url, data = {}, config = null) {
    return axios.post(url, data, config);
  }

  put(url, data = {}, config = null) {
    return axios.put(url, data, config);
  }

  patch(url, data = {}, config = null) {
    return axios.patch(url, data, config);
  }

  delete(url, config = null) {
    return axios.delete(url, config);
  }
  /**
   * 擬似的なリクエスト呼び出しを演出する
   * @param {number} ms
   * @returns
   */
  pseudoRequest(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export default new HttpRequest();
