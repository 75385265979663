import { Controller } from '@hotwired/stimulus';
import { Tooltip } from 'bootstrap';

// Connects to data-controller="partial--share"
export default class extends Controller {
  elem = document.getElementById('share-button');
  connect() {
    this.initTooltip();
  }

  initTooltip(title = 'Copy to clipboard') {
    const t = Tooltip.getInstance(this.elem);
    if (!t) {
      new Tooltip(this.elem, {
        title,
        trigger: 'hover focus',
        customClass: 'mb-2',
      });
    }
  }

  leave() {
    const t = Tooltip.getInstance(this.elem);
    t.dispose();
    this.initTooltip();
  }

  copy({ params: { url } }) {
    const t = Tooltip.getInstance(this.elem);
    t._config.title = 'Copied!';
    t.show();

    setTimeout(
      function () {
        const t = Tooltip.getInstance(this.elem);
        if (t) {
          t.dispose();
          this.initTooltip();
        }
      }.bind(this),
      2000,
    );

    return navigator.clipboard.writeText(url);
  }
}
