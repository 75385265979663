import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="admins--schedule-search"
export default class extends Controller {
  connect() {}

  // month change
  clear() {
    location.href = location.pathname;
  }
}
