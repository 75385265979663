import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

// Connects to data-controller="contact"
export default class extends Controller {
  static targets = [
    'email',
    'message',
    'emailValidation',
    'messageValidation',
    'submit',
  ];
  static values = { email: String, message: String };
  connect() {
    const m = document.getElementById('contactModal');
    const modal = new Modal(m);
    modal.show();
    this.validate();
  }

  inputEmail(e) {
    this.emailValue = e.target.value;
  }
  inputMessage(e) {
    this.messageValue = e.target.value;
  }

  emailValueChanged() {
    this.validate();
  }

  messageValueChanged() {
    this.validate();
  }

  validate() {
    this.submitTarget.disabled = !(
      this.emailValue.trim() && this.messageValue.trim()
    );
  }

  showModal() {
    const m = document.getElementById('contactModal');
    const modal = new Modal(m);
    modal.show();
  }

  hideModal() {
    const m = document.getElementById('contactModal');
    const modal = Modal.getInstance(m);
    modal.hide();
    this.resetForm();
  }

  resetForm() {
    this.emailValue = '';
    this.emailTarget.value = '';
    this.messageValue = '';
    this.messageTarget.value = '';
    this.emailValidationTarget.textContent = '';
    this.messageValidationTarget.textContent = '';
    this.submitTarget.disabled = true;
  }
  onSubmit(e) {
    e.preventDefault();
    this.element.requestSubmit();
    this.hideModal();
  }
}
