import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
// Connects to data-controller="search"
export default class extends Controller {
  static targets = [
    'place',
    'placeCompact',
    'date',
    'dateInput',
    'dateCompact',
    'placeDisplay',
    'dateDisplay',
  ];
  static values = {
    placeId: Number,
    date: String,
    places: Array,
  };

  connect() {
    flatpickr(this.dateInputTarget, {
      locale: 'en',
      dateFormat: 'Y/m/d',
      inline: true,
      minDate: new Date().fp_incr(1),
    });
  }

  /**
   * モーダルの状態を初期化
   * @param {*} event
   */
  modalInitialize(event) {
    this.closePlace();
    this.closeDate();
    if (!this.placeIdValue) {
      this.openPlace();
    } else if (!this.dateValue) {
      this.openDate();
    }
  }

  /**
   * Clear allボタンが押されたときに呼ばれる
   */
  resetAll() {
    this.placeIdValue = null;
    this.dateValue = '';
    this.openPlace();
    this.closeDate();
  }

  /**
   * 場所を選択した際に呼ばれる
   * @param {*} param0
   */
  clickPlace({ params: { placeId } }) {
    this.placeIdValue = placeId;
    this.closePlace();
    if (!this.dateValue) {
      this.openDate();
    }
  }

  /**
   * 日付を選択した際に呼ばれる
   * @param {*} event
   */
  clickDate(event) {
    this.dateValue = event.target.value;
    this.closeDate();
    if (!this.placeIdValue) {
      this.openPlace();
    }
  }

  /**
   * 場所をリセットする
   */
  resetPlace() {
    this.placeIdValue = null;
    this.openPlace();
    this.closeDate();
  }

  /**
   * 日付をリセットする
   */
  resetDate() {
    this.dateValue = '';
    this.closePlace();
    this.openDate();
  }

  /**
   * 場所選択の要素を開く
   */
  openPlace() {
    this.placeTarget.hidden = false;
    this.placeCompactTarget.hidden = true;
  }

  /**
   * 場所選択の要素を閉じる
   */
  closePlace() {
    this.placeTarget.hidden = true;
    this.placeCompactTarget.hidden = false;
  }

  /**
   * 日付選択の要素を開く
   */
  openDate() {
    this.dateTarget.hidden = false;
    this.dateCompactTarget.hidden = true;
  }

  /**
   * 日付選択の要素を閉じる
   */
  closeDate() {
    this.dateTarget.hidden = true;
    this.dateCompactTarget.hidden = false;
  }

  /**
   * 検索ボタンが押されたときに呼ばれる
   */
  search() {
    const placeId =
      this.placeIdValue === 0 || isNaN(this.placeIdValue)
        ? ''
        : this.placeIdValue ?? '';
    window.location.href = `/?place_id=${placeId}&date=${this.dateValue ?? ''}`;
  }

  /**
   * placeIdの値が変更されたときに呼ばれる
   * @param {string} value
   * @param {string} previousValue
   */
  placeIdValueChanged(value, previousValue) {
    if (!value) {
      this.placeDisplayTarget.innerHTML = I18n.search.place_input_default;
    } else {
      const selected = this.placesValue.find(
        (place) => place.id === Number(value),
      );
      this.placeDisplayTarget.innerHTML = selected.name;
    }
  }

  /**
   * dateの値が変更されたときに呼ばれる
   * @param {string} value
   * @param {string} previousValue
   */
  dateValueChanged(value, previousValue) {
    if (!value) {
      this.dateDisplayTarget.innerHTML = I18n.search.date_input_default;
    } else {
      this.dateDisplayTarget.innerHTML = value;
    }
  }
}
